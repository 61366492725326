/**
 * Note: service workerからはuseRuntimeConfigを使えないのでここでconfigを定義
 */
const envFirebaseConfigStaging = {
  apiKey: 'AIzaSyDYBAzF1QtDFvSXX2Q8Ffkt7LQ9LuHH0FQ',
  authDomain: 'my-vket-dev.firebaseapp.com',
  projectId: 'my-vket-dev',
  storageBucket: 'my-vket-dev.appspot.com',
  messagingSenderId: '363556550263',
  appId: '1:363556550263:web:e4ce7e2f4163f8ecba3872',
}
const envFirebaseConfigProduction = {
  apiKey: 'AIzaSyA55PDqECXXYfM1PBiASHNuTJ-aQsuC_n4',
  authDomain: 'my-vket-prod.firebaseapp.com',
  projectId: 'my-vket-prod',
  storageBucket: 'my-vket-prod.appspot.com',
  messagingSenderId: '914551065502',
  appId: '1:914551065502:web:5c60c760ececa57b83e48f',
}

// NOTE: nuxt.config.tsのviteオプションで設定している
const isProd = import.meta.env.OUTPUT_ENV === 'production'
export const firebaseConfig = isProd
  ? envFirebaseConfigProduction
  : envFirebaseConfigStaging
