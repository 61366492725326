import { InjectionKey } from 'vue'

import { getToken, getMessaging } from 'firebase/messaging'
import { initializeApp } from 'firebase/app'
import { firebaseConfig } from '@/service_worker/config'

// modules
import { DEVICE_TYPE, DeviceType, PushSetting } from '@/models/pushSetting'

// repositories
import { useRepositoryFactory } from '@/composables/repository/useRepositoryFactory'
import {
  postPushTokensResponse,
  getPushSettingsResponse,
  PostPushSettingsRequest,
  postPushSettingsResponse,
} from '@/composables/repository/useWebPushRepository'
import { isFetchError } from '@/composables/repository/useOhmyfetch'

export type webPushStateType = {
  /** WebPushを許可しているか */
  isWebPushGranted: boolean
  /** WebPushの許可を求める期間である */
  isConfirmInterval: boolean
  /** WebPushをサポートしている環境である */
  isSupportWebPush: boolean
  /** WebPush関連の初期化が完了している */
  isInitialized: boolean
}

/**
 * Note: PWAのWeb Push用の処理を実装している
 * Web PushにはFCMを使っているため、firebase周りの処理はステージング環境を使うようにしている
 */
export const useWebPush = () => {
  const config = useRuntimeConfig()
  const pushSetting = ref<PushSetting | null>(null)
  const state = reactive<webPushStateType>({
    isWebPushGranted: false,
    isConfirmInterval: false,
    isSupportWebPush: false,
    isInitialized: false,
  })
  // 通知許可インターバル（通常）：日（day）
  const webPushConfirmIntervalDefault = 1
  // 通知許可インターバル（通知拒否時）：日（day）
  const webPushConfirmIntervalDenied = 14
  // 通知許可インターバル（最大拒否回数以上拒否された時）：日（day）
  const webPushConfirmIntervalLong = 30
  // 最大拒否回数
  const maxDeniedCount = 3

  // repository
  const repositoryFactory = useRepositoryFactory()
  const webPushRepository = repositoryFactory.get('webPush')

  const init = async () => {
    if (process.server) return

    if (!('serviceWorker' in navigator)) {
      console.error(
        "Service Worker isn't supported on this browser, disable or hide UI."
      )
      return
    }
    if (!('PushManager' in window)) {
      console.error("Push isn't supported on this browser, disable or hide UI.")
      return
    }

    checkIsWebPushGranted()
    checkIsConfirmInterval()
    await checkIsSupportWebPush()

    const permission = Notification.permission
    if (permission === 'denied' || permission === 'default') {
      setLocalStorageValue(WEB_PUSH_TOKEN_STORAGE_KEY, 'false')
    }
    if (permission === 'granted') {
      removeLocalStorageValue(WEB_PUSH_DENIED_COUNT_KEY)
      // NOTE: 手動で通知を有効にした場合を考慮し登録処理を行う
      await registerToken()
    }
    // 初期化完了フラグを更新
    state.isInitialized = true
  }

  const subscribe = () => {
    return new Promise((resolve) => {
      Notification.requestPermission((_permission) => {
        if (_permission === 'granted') {
          // バックエンド側への登録中画面が固まるので非同期で処理を行う
          removeLocalStorageValue(WEB_PUSH_DENIED_COUNT_KEY)
          registerToken()
        }
        resolve(_permission)
      })
    })
  }

  /**
   * デバイス情報、FCMからトークンを取得し「Webプッシュ用デバイストークン登録API」を実行する
   * クッキーで登録済みかどうかチェックを行う
   * ※有効期限はプロジェクトのデフォルト設定である30日としている
   */
  const registerToken = async () => {
    // NOTE: vite pwaでyarn buildした際にproductionとしてファイルが作成されるのでruntime configではなくこちらを参照する
    const isServiceWorkerProduction = import.meta.env.PROD
    const isRegister = getLocalStorageValue(WEB_PUSH_TOKEN_STORAGE_KEY)
    if (isRegister === 'true' && config.public.NUXT_ENV_OUTPUT_ENV !== 'mock')
      return

    try {
      const app = initializeApp(firebaseConfig)
      const messaging = getMessaging(app)

      // NOTE: https://vite-pwa-org.netlify.app/guide/development.html#injectmanifest-strategy
      const registration = await navigator.serviceWorker.register(
        isServiceWorkerProduction ? '/service-worker.js' : '/dev-sw.js?dev-sw',
        {
          type: isServiceWorkerProduction ? 'classic' : 'module',
        }
      )

      const token = await getToken(messaging, {
        serviceWorkerRegistration: registration,
      })
      const userAgent = navigator.userAgent
      const deviceType: DeviceType = (() => {
        if (userAgent.includes('iPhone')) {
          return DEVICE_TYPE.IOS
        }
        if (userAgent.includes('iPad')) {
          return DEVICE_TYPE.IOS
        }
        if (userAgent.includes('Android')) {
          return DEVICE_TYPE.ANDROID
        }
        return DEVICE_TYPE.PC
      })()
      const response = await webPushRepository.post.postPushTokens({
        token,
        deviceType,
      })

      if (!isValueOf(postPushTokensResponse, response)) {
        console.error('An API response is different.')
      }

      if (config.public.NUXT_ENV_OUTPUT_ENV !== 'mock')
        setLocalStorageValue(WEB_PUSH_TOKEN_STORAGE_KEY, 'true')
    } catch (e) {
      if (isFetchError(e)) {
        console.error(e)
        return []
      }
      throw e
    }
  }

  const getSettings = async () => {
    try {
      const response = await webPushRepository.get.getPushSettings()
      if (!isValueOf(getPushSettingsResponse, response)) {
        console.error('An API response is different.')
      }
      pushSetting.value = response.pushSetting
      return response.pushSetting
    } catch (e) {
      if (isFetchError(e)) {
        console.error(e)
        return []
      }
      throw e
    }
  }

  const updateSettings = async (params: PostPushSettingsRequest) => {
    try {
      const response = await webPushRepository.post.postPushSettings(params)
      if (!isValueOf(postPushSettingsResponse, response)) {
        console.error('An API response is different.')
      }
      pushSetting.value = response.pushSetting
      return response.pushSetting
    } catch (e) {
      if (isFetchError(e)) {
        console.error(e)
        return []
      }
      throw e
    }
  }

  /**
   * 通知許可しているか確認
   */
  const checkIsWebPushGranted = () => {
    if (Notification.permission === 'granted') {
      state.isWebPushGranted = true
    }
  }

  /**
   * 通知許可確認インターバル以降のアクセスか確認
   */
  const checkIsConfirmInterval = () => {
    const lastConfirmDateStr = getLocalStorageValue(WEB_PUSH_LAST_CONFIRM_KEY)
    const permission = Notification.permission
    if (permission === 'granted') {
      // 許可済みなら必ず表示拒否期間とする
      state.isConfirmInterval = false
    }
    if (!lastConfirmDateStr) {
      // ストレージに対象の値が設定されていない場合、（通知許可見送りされていないので）trueを設定
      state.isConfirmInterval = true
    } else {
      const deniedCountStr = getLocalStorageValue(WEB_PUSH_DENIED_COUNT_KEY)
      if (deniedCountStr && Number(deniedCountStr) >= maxDeniedCount) {
        const nextConfirmDate = addDateTime(
          webPushConfirmIntervalLong,
          'day',
          lastConfirmDateStr
        )
        state.isConfirmInterval = isAfterTargetDate(nextConfirmDate)
      } else {
        const interval =
          permission === 'default'
            ? webPushConfirmIntervalDefault
            : webPushConfirmIntervalDenied
        const nextConfirmDate = addDateTime(interval, 'day', lastConfirmDateStr)
        state.isConfirmInterval = isAfterTargetDate(nextConfirmDate)
      }
    }
  }

  /**
   * 通知許可確認ストレージを設定
   */
  const setWebPushConfirmDate = () => {
    const date = new Date()
    const dateStr = formatDateTimeLocal(date)
    setLocalStorageValue(WEB_PUSH_LAST_CONFIRM_KEY, dateStr)
  }

  /**
   * 通知許可の拒否カウントを設定
   */
  const setWebPushDeniedCount = () => {
    const deniedCountStr = getLocalStorageValue(WEB_PUSH_DENIED_COUNT_KEY)
    if (!deniedCountStr) {
      // 拒否されていない場合
      setLocalStorageValue(WEB_PUSH_DENIED_COUNT_KEY, '1')
    } else {
      const deniedCount = Number(deniedCountStr)
      setLocalStorageValue(
        WEB_PUSH_DENIED_COUNT_KEY,
        (deniedCount + 1).toString()
      )
    }
  }

  /**
   * WebPushをサポートしているか確認
   */
  const checkIsSupportWebPush = async () => {
    if (!('Notification' in window)) {
      return
    }
    if (!('serviceWorker' in navigator)) {
      return
    }
    try {
      const sw = await navigator.serviceWorker.ready
      if (!('pushManager' in sw)) {
        return
      }
      state.isSupportWebPush = true
    } catch (e) {
      console.error(e)
    }
  }

  /**
   * 通知許可確認関連の情報更新
   * TODO: シンプルな処理なので、関数でくくる必要ないかもしれない。HoPwaRecommendNotificationの「通知拒否時のメソッドをまとめる」対応の際に再考する
   */
  const updateState = () => {
    checkIsWebPushGranted()
    checkIsConfirmInterval()
  }

  const skipSubscribe = () => {
    setWebPushConfirmDate()
    setWebPushDeniedCount()
    updateState()
  }

  return {
    state: readonly(state),
    init,
    subscribe,
    pushSetting: readonly(pushSetting),
    getSettings,
    registerToken,
    updateSettings,
    setWebPushConfirmDate,
    setWebPushDeniedCount,
    updateState,
    skipSubscribe,
  }
}

export type WebPush = ReturnType<typeof useWebPush>
export const webPushInjectionKey: InjectionKey<WebPush> = Symbol('web-push')
